import _crypto from "crypto";
import _fs from "fs";
import _tty from "tty";
import _path from "path";
import _process from "process";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;
var process = _process;
Object.defineProperty(exports, "__esModule", {
  value: !0
});

var extendStatics = function (d, b) {
  extendStatics = Object.setPrototypeOf || {
    __proto__: []
  } instanceof Array && function (b, c) {
    b.__proto__ = c;
  } || function (b, c) {
    for (var d in c) c.hasOwnProperty(d) && (b[d] = c[d]);
  };

  return extendStatics(d, b);
};

function __extends(d, b) {
  function f() {
    this.constructor = d;
  }

  extendStatics(d, b);
  d.prototype = null === b ? Object.create(b) : (f.prototype = b.prototype, new f());
}

function __values(d) {
  var b = "function" === typeof Symbol && d[Symbol.iterator],
      f = 0;
  return b ? b.call(d) : {
    next: function () {
      d && f >= d.length && (d = void 0);
      return {
        value: d && d[f++],
        done: !d
      };
    }
  };
}

function __read(d, b) {
  var f = "function" === typeof Symbol && d[Symbol.iterator];
  if (!f) return d;
  d = f.call(d);
  var c,
      t = [];

  try {
    for (; (void 0 === b || 0 < b--) && !(c = d.next()).done;) t.push(c.value);
  } catch (y) {
    var v = {
      error: y
    };
  } finally {
    try {
      c && !c.done && (f = d["return"]) && f.call(d);
    } finally {
      if (v) throw v.error;
    }
  }

  return t;
}

function __spread() {
  for (var d = [], b = 0; b < arguments.length; b++) d = d.concat(__read(arguments[b]));

  return d;
}

var globalObj = "undefined" !== typeof globalThis ? globalThis : "undefined" !== typeof _global ? _global : {},
    BigIntPolyfill = "undefined" !== typeof BigInt ? BigInt : globalObj.BigInt || Number,
    exportedDataView = DataView;
exportedDataView.prototype.setBigUint64 || (exportedDataView.prototype.setBigUint64 = function (d, b, f) {
  if (b < Math.pow(2, 32)) {
    b = Number(b);
    var c = 0;
  } else {
    c = b.toString(2);
    b = "";

    for (var t = 0; t < 64 - c.length; t++) b += "0";

    b += c;
    c = parseInt(b.substring(0, 32), 2);
    b = parseInt(b.substring(32), 2);
  }

  this.setUint32(d + (f ? 0 : 4), b, f);
  this.setUint32(d + (f ? 4 : 0), c, f);
}, exportedDataView.prototype.getBigUint64 = function (d, b) {
  var f = this.getUint32(d + (b ? 0 : 4), b);
  d = this.getUint32(d + (b ? 4 : 0), b);
  f = f.toString(2);
  d = d.toString(2);
  b = "";

  for (var c = 0; c < 32 - f.length; c++) b += "0";

  return BigIntPolyfill("0b" + d + (b + f));
});

var DataViewPolyfill = exportedDataView,
    isomorphicBuffer = Buffer,
    NS_PER_SEC = 1000000000,
    getBigIntHrtime = function (d) {
  return function (b) {
    b = d(b);
    return b[0] * NS_PER_SEC + b[1];
  };
},
    crypto = _crypto,
    fs = _fs,
    isTTY = _tty.isatty,
    path = _path,
    bigIntHrtime = getBigIntHrtime(process.hrtime);

process.hrtime && process.hrtime.bigint && (bigIntHrtime = process.hrtime.bigint);

var bindings = {
  hrtime: bigIntHrtime,
  exit: function (d) {
    process.exit(d);
  },
  kill: function (d) {
    process.kill(process.pid, d);
  },
  randomFillSync: crypto.randomFillSync,
  isTTY,
  fs,
  path
},
    _a,
    WASI_ESUCCESS = 0,
    WASI_E2BIG = 1,
    WASI_EACCES = 2,
    WASI_EADDRINUSE = 3,
    WASI_EADDRNOTAVAIL = 4,
    WASI_EAFNOSUPPORT = 5,
    WASI_EAGAIN = 6,
    WASI_EALREADY = 7,
    WASI_EBADF = 8,
    WASI_EBADMSG = 9,
    WASI_EBUSY = 10,
    WASI_ECANCELED = 11,
    WASI_ECHILD = 12,
    WASI_ECONNABORTED = 13,
    WASI_ECONNREFUSED = 14,
    WASI_ECONNRESET = 15,
    WASI_EDEADLK = 16,
    WASI_EDESTADDRREQ = 17,
    WASI_EDOM = 18,
    WASI_EDQUOT = 19,
    WASI_EEXIST = 20,
    WASI_EFAULT = 21,
    WASI_EFBIG = 22,
    WASI_EHOSTUNREACH = 23,
    WASI_EIDRM = 24,
    WASI_EILSEQ = 25,
    WASI_EINPROGRESS = 26,
    WASI_EINTR = 27,
    WASI_EINVAL = 28,
    WASI_EIO = 29,
    WASI_EISCONN = 30,
    WASI_EISDIR = 31,
    WASI_ELOOP = 32,
    WASI_EMFILE = 33,
    WASI_EMLINK = 34,
    WASI_EMSGSIZE = 35,
    WASI_EMULTIHOP = 36,
    WASI_ENAMETOOLONG = 37,
    WASI_ENETDOWN = 38,
    WASI_ENETRESET = 39,
    WASI_ENETUNREACH = 40,
    WASI_ENFILE = 41,
    WASI_ENOBUFS = 42,
    WASI_ENODEV = 43,
    WASI_ENOENT = 44,
    WASI_ENOEXEC = 45,
    WASI_ENOLCK = 46,
    WASI_ENOLINK = 47,
    WASI_ENOMEM = 48,
    WASI_ENOMSG = 49,
    WASI_ENOPROTOOPT = 50,
    WASI_ENOSPC = 51,
    WASI_ENOSYS = 52,
    WASI_ENOTCONN = 53,
    WASI_ENOTDIR = 54,
    WASI_ENOTEMPTY = 55,
    WASI_ENOTRECOVERABLE = 56,
    WASI_ENOTSOCK = 57,
    WASI_ENOTTY = 59,
    WASI_ENXIO = 60,
    WASI_EOVERFLOW = 61,
    WASI_EOWNERDEAD = 62,
    WASI_EPERM = 63,
    WASI_EPIPE = 64,
    WASI_EPROTO = 65,
    WASI_EPROTONOSUPPORT = 66,
    WASI_EPROTOTYPE = 67,
    WASI_ERANGE = 68,
    WASI_EROFS = 69,
    WASI_ESPIPE = 70,
    WASI_ESRCH = 71,
    WASI_ESTALE = 72,
    WASI_ETIMEDOUT = 73,
    WASI_ETXTBSY = 74,
    WASI_EXDEV = 75,
    WASI_ENOTCAPABLE = 76,
    WASI_SIGABRT = 0,
    WASI_SIGALRM = 1,
    WASI_SIGBUS = 2,
    WASI_SIGCHLD = 3,
    WASI_SIGCONT = 4,
    WASI_SIGFPE = 5,
    WASI_SIGHUP = 6,
    WASI_SIGILL = 7,
    WASI_SIGINT = 8,
    WASI_SIGKILL = 9,
    WASI_SIGPIPE = 10,
    WASI_SIGQUIT = 11,
    WASI_SIGSEGV = 12,
    WASI_SIGSTOP = 13,
    WASI_SIGTERM = 14,
    WASI_SIGTRAP = 15,
    WASI_SIGTSTP = 16,
    WASI_SIGTTIN = 17,
    WASI_SIGTTOU = 18,
    WASI_SIGURG = 19,
    WASI_SIGUSR1 = 20,
    WASI_SIGUSR2 = 21,
    WASI_SIGVTALRM = 22,
    WASI_SIGXCPU = 23,
    WASI_SIGXFSZ = 24,
    WASI_FILETYPE_UNKNOWN = 0,
    WASI_FILETYPE_BLOCK_DEVICE = 1,
    WASI_FILETYPE_CHARACTER_DEVICE = 2,
    WASI_FILETYPE_DIRECTORY = 3,
    WASI_FILETYPE_REGULAR_FILE = 4,
    WASI_FILETYPE_SOCKET_STREAM = 6,
    WASI_FILETYPE_SYMBOLIC_LINK = 7,
    WASI_FDFLAG_APPEND = 1,
    WASI_FDFLAG_DSYNC = 2,
    WASI_FDFLAG_NONBLOCK = 4,
    WASI_FDFLAG_RSYNC = 8,
    WASI_FDFLAG_SYNC = 16,
    WASI_RIGHT_FD_DATASYNC = BigIntPolyfill(1),
    WASI_RIGHT_FD_READ = BigIntPolyfill(2),
    WASI_RIGHT_FD_SEEK = BigIntPolyfill(4),
    WASI_RIGHT_FD_FDSTAT_SET_FLAGS = BigIntPolyfill(8),
    WASI_RIGHT_FD_SYNC = BigIntPolyfill(16),
    WASI_RIGHT_FD_TELL = BigIntPolyfill(32),
    WASI_RIGHT_FD_WRITE = BigIntPolyfill(64),
    WASI_RIGHT_FD_ADVISE = BigIntPolyfill(128),
    WASI_RIGHT_FD_ALLOCATE = BigIntPolyfill(256),
    WASI_RIGHT_PATH_CREATE_DIRECTORY = BigIntPolyfill(512),
    WASI_RIGHT_PATH_CREATE_FILE = BigIntPolyfill(1024),
    WASI_RIGHT_PATH_LINK_SOURCE = BigIntPolyfill(2048),
    WASI_RIGHT_PATH_LINK_TARGET = BigIntPolyfill(4096),
    WASI_RIGHT_PATH_OPEN = BigIntPolyfill(8192),
    WASI_RIGHT_FD_READDIR = BigIntPolyfill(16384),
    WASI_RIGHT_PATH_READLINK = BigIntPolyfill(32768),
    WASI_RIGHT_PATH_RENAME_SOURCE = BigIntPolyfill(65536),
    WASI_RIGHT_PATH_RENAME_TARGET = BigIntPolyfill(131072),
    WASI_RIGHT_PATH_FILESTAT_GET = BigIntPolyfill(262144),
    WASI_RIGHT_PATH_FILESTAT_SET_SIZE = BigIntPolyfill(524288),
    WASI_RIGHT_PATH_FILESTAT_SET_TIMES = BigIntPolyfill(1048576),
    WASI_RIGHT_FD_FILESTAT_GET = BigIntPolyfill(2097152),
    WASI_RIGHT_FD_FILESTAT_SET_SIZE = BigIntPolyfill(4194304),
    WASI_RIGHT_FD_FILESTAT_SET_TIMES = BigIntPolyfill(8388608),
    WASI_RIGHT_PATH_SYMLINK = BigIntPolyfill(16777216),
    WASI_RIGHT_PATH_REMOVE_DIRECTORY = BigIntPolyfill(33554432),
    WASI_RIGHT_PATH_UNLINK_FILE = BigIntPolyfill(67108864),
    WASI_RIGHT_POLL_FD_READWRITE = BigIntPolyfill(134217728),
    WASI_RIGHT_SOCK_SHUTDOWN = BigIntPolyfill(268435456),
    RIGHTS_ALL = WASI_RIGHT_FD_DATASYNC | WASI_RIGHT_FD_READ | WASI_RIGHT_FD_SEEK | WASI_RIGHT_FD_FDSTAT_SET_FLAGS | WASI_RIGHT_FD_SYNC | WASI_RIGHT_FD_TELL | WASI_RIGHT_FD_WRITE | WASI_RIGHT_FD_ADVISE | WASI_RIGHT_FD_ALLOCATE | WASI_RIGHT_PATH_CREATE_DIRECTORY | WASI_RIGHT_PATH_CREATE_FILE | WASI_RIGHT_PATH_LINK_SOURCE | WASI_RIGHT_PATH_LINK_TARGET | WASI_RIGHT_PATH_OPEN | WASI_RIGHT_FD_READDIR | WASI_RIGHT_PATH_READLINK | WASI_RIGHT_PATH_RENAME_SOURCE | WASI_RIGHT_PATH_RENAME_TARGET | WASI_RIGHT_PATH_FILESTAT_GET | WASI_RIGHT_PATH_FILESTAT_SET_SIZE | WASI_RIGHT_PATH_FILESTAT_SET_TIMES | WASI_RIGHT_FD_FILESTAT_GET | WASI_RIGHT_FD_FILESTAT_SET_TIMES | WASI_RIGHT_FD_FILESTAT_SET_SIZE | WASI_RIGHT_PATH_SYMLINK | WASI_RIGHT_PATH_UNLINK_FILE | WASI_RIGHT_PATH_REMOVE_DIRECTORY | WASI_RIGHT_POLL_FD_READWRITE | WASI_RIGHT_SOCK_SHUTDOWN,
    RIGHTS_BLOCK_DEVICE_BASE = RIGHTS_ALL,
    RIGHTS_BLOCK_DEVICE_INHERITING = RIGHTS_ALL,
    RIGHTS_CHARACTER_DEVICE_BASE = RIGHTS_ALL,
    RIGHTS_CHARACTER_DEVICE_INHERITING = RIGHTS_ALL,
    RIGHTS_REGULAR_FILE_BASE = WASI_RIGHT_FD_DATASYNC | WASI_RIGHT_FD_READ | WASI_RIGHT_FD_SEEK | WASI_RIGHT_FD_FDSTAT_SET_FLAGS | WASI_RIGHT_FD_SYNC | WASI_RIGHT_FD_TELL | WASI_RIGHT_FD_WRITE | WASI_RIGHT_FD_ADVISE | WASI_RIGHT_FD_ALLOCATE | WASI_RIGHT_FD_FILESTAT_GET | WASI_RIGHT_FD_FILESTAT_SET_SIZE | WASI_RIGHT_FD_FILESTAT_SET_TIMES | WASI_RIGHT_POLL_FD_READWRITE,
    RIGHTS_REGULAR_FILE_INHERITING = BigIntPolyfill(0),
    RIGHTS_DIRECTORY_BASE = WASI_RIGHT_FD_FDSTAT_SET_FLAGS | WASI_RIGHT_FD_SYNC | WASI_RIGHT_FD_ADVISE | WASI_RIGHT_PATH_CREATE_DIRECTORY | WASI_RIGHT_PATH_CREATE_FILE | WASI_RIGHT_PATH_LINK_SOURCE | WASI_RIGHT_PATH_LINK_TARGET | WASI_RIGHT_PATH_OPEN | WASI_RIGHT_FD_READDIR | WASI_RIGHT_PATH_READLINK | WASI_RIGHT_PATH_RENAME_SOURCE | WASI_RIGHT_PATH_RENAME_TARGET | WASI_RIGHT_PATH_FILESTAT_GET | WASI_RIGHT_PATH_FILESTAT_SET_SIZE | WASI_RIGHT_PATH_FILESTAT_SET_TIMES | WASI_RIGHT_FD_FILESTAT_GET | WASI_RIGHT_FD_FILESTAT_SET_TIMES | WASI_RIGHT_PATH_SYMLINK | WASI_RIGHT_PATH_UNLINK_FILE | WASI_RIGHT_PATH_REMOVE_DIRECTORY | WASI_RIGHT_POLL_FD_READWRITE,
    RIGHTS_DIRECTORY_INHERITING = RIGHTS_DIRECTORY_BASE | RIGHTS_REGULAR_FILE_BASE,
    RIGHTS_SOCKET_BASE = WASI_RIGHT_FD_READ | WASI_RIGHT_FD_FDSTAT_SET_FLAGS | WASI_RIGHT_FD_WRITE | WASI_RIGHT_FD_FILESTAT_GET | WASI_RIGHT_POLL_FD_READWRITE | WASI_RIGHT_SOCK_SHUTDOWN,
    RIGHTS_SOCKET_INHERITING = RIGHTS_ALL,
    RIGHTS_TTY_BASE = WASI_RIGHT_FD_READ | WASI_RIGHT_FD_FDSTAT_SET_FLAGS | WASI_RIGHT_FD_WRITE | WASI_RIGHT_FD_FILESTAT_GET | WASI_RIGHT_POLL_FD_READWRITE,
    RIGHTS_TTY_INHERITING = BigIntPolyfill(0),
    WASI_CLOCK_REALTIME = 0,
    WASI_CLOCK_MONOTONIC = 1,
    WASI_CLOCK_PROCESS_CPUTIME_ID = 2,
    WASI_CLOCK_THREAD_CPUTIME_ID = 3,
    WASI_EVENTTYPE_CLOCK = 0,
    WASI_EVENTTYPE_FD_READ = 1,
    WASI_EVENTTYPE_FD_WRITE = 2,
    WASI_FILESTAT_SET_ATIM = 1,
    WASI_FILESTAT_SET_ATIM_NOW = 2,
    WASI_FILESTAT_SET_MTIM = 4,
    WASI_FILESTAT_SET_MTIM_NOW = 8,
    WASI_O_CREAT = 1,
    WASI_O_DIRECTORY = 2,
    WASI_O_EXCL = 4,
    WASI_O_TRUNC = 8,
    WASI_PREOPENTYPE_DIR = 0,
    WASI_STDIN_FILENO = 0,
    WASI_STDOUT_FILENO = 1,
    WASI_STDERR_FILENO = 2,
    WASI_WHENCE_SET = 0,
    WASI_WHENCE_CUR = 1,
    WASI_WHENCE_END = 2,
    ERROR_MAP = {
  E2BIG: WASI_E2BIG,
  EACCES: WASI_EACCES,
  EADDRINUSE: WASI_EADDRINUSE,
  EADDRNOTAVAIL: WASI_EADDRNOTAVAIL,
  EAFNOSUPPORT: WASI_EAFNOSUPPORT,
  EALREADY: WASI_EALREADY,
  EAGAIN: WASI_EAGAIN,
  EBADF: WASI_EBADF,
  EBADMSG: WASI_EBADMSG,
  EBUSY: WASI_EBUSY,
  ECANCELED: WASI_ECANCELED,
  ECHILD: WASI_ECHILD,
  ECONNABORTED: WASI_ECONNABORTED,
  ECONNREFUSED: WASI_ECONNREFUSED,
  ECONNRESET: WASI_ECONNRESET,
  EDEADLOCK: WASI_EDEADLK,
  EDESTADDRREQ: WASI_EDESTADDRREQ,
  EDOM: WASI_EDOM,
  EDQUOT: WASI_EDQUOT,
  EEXIST: WASI_EEXIST,
  EFAULT: WASI_EFAULT,
  EFBIG: WASI_EFBIG,
  EHOSTDOWN: WASI_EHOSTUNREACH,
  EHOSTUNREACH: WASI_EHOSTUNREACH,
  EIDRM: WASI_EIDRM,
  EILSEQ: WASI_EILSEQ,
  EINPROGRESS: WASI_EINPROGRESS,
  EINTR: WASI_EINTR,
  EINVAL: WASI_EINVAL,
  EIO: WASI_EIO,
  EISCONN: WASI_EISCONN,
  EISDIR: WASI_EISDIR,
  ELOOP: WASI_ELOOP,
  EMFILE: WASI_EMFILE,
  EMLINK: WASI_EMLINK,
  EMSGSIZE: WASI_EMSGSIZE,
  EMULTIHOP: WASI_EMULTIHOP,
  ENAMETOOLONG: WASI_ENAMETOOLONG,
  ENETDOWN: WASI_ENETDOWN,
  ENETRESET: WASI_ENETRESET,
  ENETUNREACH: WASI_ENETUNREACH,
  ENFILE: WASI_ENFILE,
  ENOBUFS: WASI_ENOBUFS,
  ENODEV: WASI_ENODEV,
  ENOENT: WASI_ENOENT,
  ENOEXEC: WASI_ENOEXEC,
  ENOLCK: WASI_ENOLCK,
  ENOLINK: WASI_ENOLINK,
  ENOMEM: WASI_ENOMEM,
  ENOMSG: WASI_ENOMSG,
  ENOPROTOOPT: WASI_ENOPROTOOPT,
  ENOSPC: WASI_ENOSPC,
  ENOSYS: WASI_ENOSYS,
  ENOTCONN: WASI_ENOTCONN,
  ENOTDIR: WASI_ENOTDIR,
  ENOTEMPTY: WASI_ENOTEMPTY,
  ENOTRECOVERABLE: WASI_ENOTRECOVERABLE,
  ENOTSOCK: WASI_ENOTSOCK,
  ENOTTY: WASI_ENOTTY,
  ENXIO: WASI_ENXIO,
  EOVERFLOW: WASI_EOVERFLOW,
  EOWNERDEAD: WASI_EOWNERDEAD,
  EPERM: WASI_EPERM,
  EPIPE: WASI_EPIPE,
  EPROTO: WASI_EPROTO,
  EPROTONOSUPPORT: WASI_EPROTONOSUPPORT,
  EPROTOTYPE: WASI_EPROTOTYPE,
  ERANGE: WASI_ERANGE,
  EROFS: WASI_EROFS,
  ESPIPE: WASI_ESPIPE,
  ESRCH: WASI_ESRCH,
  ESTALE: WASI_ESTALE,
  ETIMEDOUT: WASI_ETIMEDOUT,
  ETXTBSY: WASI_ETXTBSY,
  EXDEV: WASI_EXDEV
},
    SIGNAL_MAP = (_a = {}, _a[WASI_SIGHUP] = "SIGHUP", _a[WASI_SIGINT] = "SIGINT", _a[WASI_SIGQUIT] = "SIGQUIT", _a[WASI_SIGILL] = "SIGILL", _a[WASI_SIGTRAP] = "SIGTRAP", _a[WASI_SIGABRT] = "SIGABRT", _a[WASI_SIGBUS] = "SIGBUS", _a[WASI_SIGFPE] = "SIGFPE", _a[WASI_SIGKILL] = "SIGKILL", _a[WASI_SIGUSR1] = "SIGUSR1", _a[WASI_SIGSEGV] = "SIGSEGV", _a[WASI_SIGUSR2] = "SIGUSR2", _a[WASI_SIGPIPE] = "SIGPIPE", _a[WASI_SIGALRM] = "SIGALRM", _a[WASI_SIGTERM] = "SIGTERM", _a[WASI_SIGCHLD] = "SIGCHLD", _a[WASI_SIGCONT] = "SIGCONT", _a[WASI_SIGSTOP] = "SIGSTOP", _a[WASI_SIGTSTP] = "SIGTSTP", _a[WASI_SIGTTIN] = "SIGTTIN", _a[WASI_SIGTTOU] = "SIGTTOU", _a[WASI_SIGURG] = "SIGURG", _a[WASI_SIGXCPU] = "SIGXCPU", _a[WASI_SIGXFSZ] = "SIGXFSZ", _a[WASI_SIGVTALRM] = "SIGVTALRM", _a),
    defaultBindings;

defaultBindings = bindings;

var STDIN_DEFAULT_RIGHTS = WASI_RIGHT_FD_DATASYNC | WASI_RIGHT_FD_READ | WASI_RIGHT_FD_SYNC | WASI_RIGHT_FD_ADVISE | WASI_RIGHT_FD_FILESTAT_GET | WASI_RIGHT_POLL_FD_READWRITE,
    STDOUT_DEFAULT_RIGHTS = WASI_RIGHT_FD_DATASYNC | WASI_RIGHT_FD_WRITE | WASI_RIGHT_FD_SYNC | WASI_RIGHT_FD_ADVISE | WASI_RIGHT_FD_FILESTAT_GET | WASI_RIGHT_POLL_FD_READWRITE,
    STDERR_DEFAULT_RIGHTS = STDOUT_DEFAULT_RIGHTS,
    msToNs = function (d) {
  var b = Math.trunc(d);
  d = BigIntPolyfill(Math.round(1000000 * (d - b)));
  return BigIntPolyfill(b) * BigIntPolyfill(1000000) + d;
},
    nsToMs = function (d) {
  "number" === typeof d && (d = Math.trunc(d));
  d = BigIntPolyfill(d);
  return Number(d / BigIntPolyfill(1000000));
},
    wrap = function (d) {
  return function () {
    for (var b = [], f = 0; f < arguments.length; f++) b[f] = arguments[f];

    try {
      return d.apply(void 0, __spread(b));
    } catch (c) {
      if (c && c.code && "string" === typeof c.code) return ERROR_MAP[c.code] || WASI_EINVAL;
      if (c instanceof WASIError) return c.errno;
      throw c;
    }
  };
},
    stat = function (d, b) {
  var f = d.FD_MAP.get(b);
  if (!f) throw new WASIError(WASI_EBADF);

  if (void 0 === f.filetype) {
    var c = d.bindings.fs.fstatSync(f.real);
    d = translateFileAttributes(d, b, c);
    b = d.rightsBase;
    c = d.rightsInheriting;
    f.filetype = d.filetype;
    f.rights || (f.rights = {
      base: b,
      inheriting: c
    });
  }

  return f;
},
    translateFileAttributes = function (d, b, f) {
  switch (!0) {
    case f.isBlockDevice():
      return {
        filetype: WASI_FILETYPE_BLOCK_DEVICE,
        rightsBase: RIGHTS_BLOCK_DEVICE_BASE,
        rightsInheriting: RIGHTS_BLOCK_DEVICE_INHERITING
      };

    case f.isCharacterDevice():
      return f = WASI_FILETYPE_CHARACTER_DEVICE, void 0 !== b && d.bindings.isTTY(b) ? {
        filetype: f,
        rightsBase: RIGHTS_TTY_BASE,
        rightsInheriting: RIGHTS_TTY_INHERITING
      } : {
        filetype: f,
        rightsBase: RIGHTS_CHARACTER_DEVICE_BASE,
        rightsInheriting: RIGHTS_CHARACTER_DEVICE_INHERITING
      };

    case f.isDirectory():
      return {
        filetype: WASI_FILETYPE_DIRECTORY,
        rightsBase: RIGHTS_DIRECTORY_BASE,
        rightsInheriting: RIGHTS_DIRECTORY_INHERITING
      };

    case f.isFIFO():
      return {
        filetype: WASI_FILETYPE_SOCKET_STREAM,
        rightsBase: RIGHTS_SOCKET_BASE,
        rightsInheriting: RIGHTS_SOCKET_INHERITING
      };

    case f.isFile():
      return {
        filetype: WASI_FILETYPE_REGULAR_FILE,
        rightsBase: RIGHTS_REGULAR_FILE_BASE,
        rightsInheriting: RIGHTS_REGULAR_FILE_INHERITING
      };

    case f.isSocket():
      return {
        filetype: WASI_FILETYPE_SOCKET_STREAM,
        rightsBase: RIGHTS_SOCKET_BASE,
        rightsInheriting: RIGHTS_SOCKET_INHERITING
      };

    case f.isSymbolicLink():
      return {
        filetype: WASI_FILETYPE_SYMBOLIC_LINK,
        rightsBase: BigIntPolyfill(0),
        rightsInheriting: BigIntPolyfill(0)
      };

    default:
      return {
        filetype: WASI_FILETYPE_UNKNOWN,
        rightsBase: BigIntPolyfill(0),
        rightsInheriting: BigIntPolyfill(0)
      };
  }
},
    WASIError = function (d) {
  function b(f) {
    var c = d.call(this) || this;
    c.errno = f;
    Object.setPrototypeOf(c, b.prototype);
    return c;
  }

  __extends(b, d);

  return b;
}(Error),
    WASIExitError = function (d) {
  function b(f) {
    var c = d.call(this, "WASI Exit error: " + f) || this;
    c.code = f;
    Object.setPrototypeOf(c, b.prototype);
    return c;
  }

  __extends(b, d);

  return b;
}(Error),
    WASIKillError = function (d) {
  function b(f) {
    var c = d.call(this, "WASI Kill signal: " + f) || this;
    c.signal = f;
    Object.setPrototypeOf(c, b.prototype);
    return c;
  }

  __extends(b, d);

  return b;
}(Error),
    WASIDefault = function () {
  function d(b) {
    var d,
        c = this,
        t = {};
    b && b.preopens ? t = b.preopens : b && b.preopenDirectories && (t = b.preopenDirectories);
    var v = {};
    b && b.env && (v = b.env);
    var y = [];
    b && b.args && (y = b.args);
    var w = defaultBindings;
    b && b.bindings && (w = b.bindings);
    this.view = this.memory = void 0;
    this.bindings = w;
    this.FD_MAP = new Map([[WASI_STDIN_FILENO, {
      real: 0,
      filetype: WASI_FILETYPE_CHARACTER_DEVICE,
      rights: {
        base: STDIN_DEFAULT_RIGHTS,
        inheriting: BigIntPolyfill(0)
      },
      path: void 0
    }], [WASI_STDOUT_FILENO, {
      real: 1,
      filetype: WASI_FILETYPE_CHARACTER_DEVICE,
      rights: {
        base: STDOUT_DEFAULT_RIGHTS,
        inheriting: BigIntPolyfill(0)
      },
      path: void 0
    }], [WASI_STDERR_FILENO, {
      real: 2,
      filetype: WASI_FILETYPE_CHARACTER_DEVICE,
      rights: {
        base: STDERR_DEFAULT_RIGHTS,
        inheriting: BigIntPolyfill(0)
      },
      path: void 0
    }]]);
    var g = this.bindings.fs,
        q = this.bindings.path;

    try {
      for (var C = __values(Object.entries(t)), B = C.next(); !B.done; B = C.next()) {
        var G = __read(B.value, 2),
            J = G[0],
            H = G[1],
            K = g.openSync(H, g.constants.O_RDONLY),
            L = __spread(this.FD_MAP.keys()).reverse()[0] + 1;

        this.FD_MAP.set(L, {
          real: K,
          filetype: WASI_FILETYPE_DIRECTORY,
          rights: {
            base: RIGHTS_DIRECTORY_BASE,
            inheriting: RIGHTS_DIRECTORY_INHERITING
          },
          fakePath: J,
          path: H
        });
      }
    } catch (a) {
      var I = {
        error: a
      };
    } finally {
      try {
        B && !B.done && (d = C.return) && d.call(C);
      } finally {
        if (I) throw I.error;
      }
    }

    var D = function (a, e) {
      c.refreshMemory();
      return Array.from({
        length: e
      }, function (e, b) {
        b = a + 8 * b;
        e = c.view.getUint32(b, !0);
        b = c.view.getUint32(b + 4, !0);
        return new Uint8Array(c.memory.buffer, e, b);
      });
    },
        k = function (a, e) {
      a = stat(c, a);
      if (e !== BigIntPolyfill(0) && (a.rights.base & e) === BigIntPolyfill(0)) throw new WASIError(WASI_EPERM);
      return a;
    },
        M = w.hrtime(),
        E = function (a) {
      switch (a) {
        case WASI_CLOCK_MONOTONIC:
          return w.hrtime();

        case WASI_CLOCK_REALTIME:
          return msToNs(Date.now());

        case WASI_CLOCK_PROCESS_CPUTIME_ID:
        case WASI_CLOCK_THREAD_CPUTIME_ID:
          return w.hrtime() - M;

        default:
          return null;
      }
    };

    this.wasiImport = {
      args_get: function (a, e) {
        c.refreshMemory();
        var b = a,
            d = e;
        y.forEach(function (a) {
          c.view.setUint32(b, d, !0);
          b += 4;
          d += isomorphicBuffer.from(c.memory.buffer).write(a + "\0", d);
        });
        return WASI_ESUCCESS;
      },
      args_sizes_get: function (a, e) {
        c.refreshMemory();
        c.view.setUint32(a, y.length, !0);
        a = y.reduce(function (a, c) {
          return a + isomorphicBuffer.byteLength(c) + 1;
        }, 0);
        c.view.setUint32(e, a, !0);
        return WASI_ESUCCESS;
      },
      environ_get: function (a, e) {
        c.refreshMemory();
        var b = a,
            d = e;
        Object.entries(v).forEach(function (a) {
          var e = __read(a, 2);

          a = e[0];
          e = e[1];
          c.view.setUint32(b, d, !0);
          b += 4;
          d += isomorphicBuffer.from(c.memory.buffer).write(a + "=" + e + "\0", d);
        });
        return WASI_ESUCCESS;
      },
      environ_sizes_get: function (a, e) {
        c.refreshMemory();
        var b = Object.entries(v).map(function (a) {
          a = __read(a, 2);
          return a[0] + "=" + a[1] + "\0";
        }),
            d = b.reduce(function (a, c) {
          return a + isomorphicBuffer.byteLength(c);
        }, 0);
        c.view.setUint32(a, b.length, !0);
        c.view.setUint32(e, d, !0);
        return WASI_ESUCCESS;
      },
      clock_res_get: function (a, e) {
        switch (a) {
          case WASI_CLOCK_MONOTONIC:
          case WASI_CLOCK_PROCESS_CPUTIME_ID:
          case WASI_CLOCK_THREAD_CPUTIME_ID:
            var b = BigIntPolyfill(1);
            break;

          case WASI_CLOCK_REALTIME:
            b = BigIntPolyfill(1000);
        }

        c.view.setBigUint64(e, b);
        return WASI_ESUCCESS;
      },
      clock_time_get: function (a, e, b) {
        c.refreshMemory();
        a = E(a);
        if (null === a) return WASI_EINVAL;
        c.view.setBigUint64(b, BigIntPolyfill(a), !0);
        return WASI_ESUCCESS;
      },
      fd_advise: wrap(function (a, c, b, d) {
        k(a, WASI_RIGHT_FD_ADVISE);
        return WASI_ENOSYS;
      }),
      fd_allocate: wrap(function (a, c, b) {
        k(a, WASI_RIGHT_FD_ALLOCATE);
        return WASI_ENOSYS;
      }),
      fd_close: wrap(function (a) {
        var e = k(a, BigIntPolyfill(0));
        g.closeSync(e.real);
        c.FD_MAP.delete(a);
        return WASI_ESUCCESS;
      }),
      fd_datasync: wrap(function (a) {
        a = k(a, WASI_RIGHT_FD_DATASYNC);
        g.fdatasyncSync(a.real);
        return WASI_ESUCCESS;
      }),
      fd_fdstat_get: wrap(function (a, e) {
        a = k(a, BigIntPolyfill(0));
        c.refreshMemory();
        c.view.setUint8(e, a.filetype);
        c.view.setUint16(e + 2, 0, !0);
        c.view.setUint16(e + 4, 0, !0);
        c.view.setBigUint64(e + 8, BigIntPolyfill(a.rights.base), !0);
        c.view.setBigUint64(e + 8 + 8, BigIntPolyfill(a.rights.inheriting), !0);
        return WASI_ESUCCESS;
      }),
      fd_fdstat_set_flags: wrap(function (a, c) {
        k(a, WASI_RIGHT_FD_FDSTAT_SET_FLAGS);
        return WASI_ENOSYS;
      }),
      fd_fdstat_set_rights: wrap(function (a, c, b) {
        a = k(a, BigIntPolyfill(0));
        if ((a.rights.base | c) > a.rights.base || (a.rights.inheriting | b) > a.rights.inheriting) return WASI_EPERM;
        a.rights.base = c;
        a.rights.inheriting = b;
        return WASI_ESUCCESS;
      }),
      fd_filestat_get: wrap(function (a, e) {
        a = k(a, WASI_RIGHT_FD_FILESTAT_GET);
        var b = g.fstatSync(a.real);
        c.refreshMemory();
        c.view.setBigUint64(e, BigIntPolyfill(b.dev), !0);
        e += 8;
        c.view.setBigUint64(e, BigIntPolyfill(b.ino), !0);
        e += 8;
        c.view.setUint8(e, a.filetype);
        e += 8;
        c.view.setBigUint64(e, BigIntPolyfill(b.nlink), !0);
        e += 8;
        c.view.setBigUint64(e, BigIntPolyfill(b.size), !0);
        e += 8;
        c.view.setBigUint64(e, msToNs(b.atimeMs), !0);
        e += 8;
        c.view.setBigUint64(e, msToNs(b.mtimeMs), !0);
        c.view.setBigUint64(e + 8, msToNs(b.ctimeMs), !0);
        return WASI_ESUCCESS;
      }),
      fd_filestat_set_size: wrap(function (a, c) {
        a = k(a, WASI_RIGHT_FD_FILESTAT_SET_SIZE);
        g.ftruncateSync(a.real, Number(c));
        return WASI_ESUCCESS;
      }),
      fd_filestat_set_times: wrap(function (a, c, b, d) {
        a = k(a, WASI_RIGHT_FD_FILESTAT_SET_TIMES);
        var e = g.fstatSync(a.real),
            h = e.atime;
        e = e.mtime;
        var f = nsToMs(E(WASI_CLOCK_REALTIME)),
            u = WASI_FILESTAT_SET_ATIM | WASI_FILESTAT_SET_ATIM_NOW;
        if ((d & u) === u) return WASI_EINVAL;
        u = WASI_FILESTAT_SET_MTIM | WASI_FILESTAT_SET_MTIM_NOW;
        if ((d & u) === u) return WASI_EINVAL;
        (d & WASI_FILESTAT_SET_ATIM) === WASI_FILESTAT_SET_ATIM ? h = nsToMs(c) : (d & WASI_FILESTAT_SET_ATIM_NOW) === WASI_FILESTAT_SET_ATIM_NOW && (h = f);
        (d & WASI_FILESTAT_SET_MTIM) === WASI_FILESTAT_SET_MTIM ? e = nsToMs(b) : (d & WASI_FILESTAT_SET_MTIM_NOW) === WASI_FILESTAT_SET_MTIM_NOW && (e = f);
        g.futimesSync(a.real, new Date(h), new Date(e));
        return WASI_ESUCCESS;
      }),
      fd_prestat_get: wrap(function (a, e) {
        a = k(a, BigIntPolyfill(0));
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        c.view.setUint8(e, WASI_PREOPENTYPE_DIR);
        c.view.setUint32(e + 4, isomorphicBuffer.byteLength(a.fakePath), !0);
        return WASI_ESUCCESS;
      }),
      fd_prestat_dir_name: wrap(function (a, e, b) {
        a = k(a, BigIntPolyfill(0));
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        isomorphicBuffer.from(c.memory.buffer).write(a.fakePath, e, b, "utf8");
        return WASI_ESUCCESS;
      }),
      fd_pwrite: wrap(function (a, e, b, d, f) {
        var h = k(a, WASI_RIGHT_FD_WRITE | WASI_RIGHT_FD_SEEK),
            m = 0;
        D(e, b).forEach(function (a) {
          for (var c = 0; c < a.byteLength;) c += g.writeSync(h.real, a, c, a.byteLength - c, Number(d) + m + c);

          m += c;
        });
        c.view.setUint32(f, m, !0);
        return WASI_ESUCCESS;
      }),
      fd_write: wrap(function (a, e, b, d) {
        var h = k(a, WASI_RIGHT_FD_WRITE),
            f = 0;
        D(e, b).forEach(function (a) {
          for (var c = 0; c < a.byteLength;) {
            var e = g.writeSync(h.real, a, c, a.byteLength - c, h.offset ? Number(h.offset) : null);
            h.offset && (h.offset += BigIntPolyfill(e));
            c += e;
          }

          f += c;
        });
        c.view.setUint32(d, f, !0);
        return WASI_ESUCCESS;
      }),
      fd_pread: wrap(function (a, e, b, d, f) {
        var h;
        a = k(a, WASI_RIGHT_FD_READ | WASI_RIGHT_FD_SEEK);
        var m = 0;

        try {
          var r = __values(D(e, b)),
              n = r.next();

          a: for (; !n.done; n = r.next()) {
            var l = n.value;

            for (e = 0; e < l.byteLength;) {
              var F = l.byteLength - e,
                  z = g.readSync(a.real, l, e, l.byteLength - e, Number(d) + m + e);
              e += z;
              m += z;
              if (0 === z || z < F) break a;
            }

            m += e;
          }
        } catch (A) {
          var q = {
            error: A
          };
        } finally {
          try {
            n && !n.done && (h = r.return) && h.call(r);
          } finally {
            if (q) throw q.error;
          }
        }

        c.view.setUint32(f, m, !0);
        return WASI_ESUCCESS;
      }),
      fd_read: wrap(function (a, e, b, d) {
        var h;
        a = k(a, WASI_RIGHT_FD_READ);
        var f = 0 === a.real,
            r = 0;

        try {
          var u = __values(D(e, b)),
              n = u.next();

          a: for (; !n.done; n = u.next()) {
            var l = n.value;

            for (e = 0; e < l.byteLength;) {
              var F = l.byteLength - e,
                  z = g.readSync(a.real, l, e, F, f || void 0 === a.offset ? null : Number(a.offset));
              f || (a.offset = (a.offset ? a.offset : BigIntPolyfill(0)) + BigIntPolyfill(z));
              e += z;
              r += z;
              if (0 === z || z < F) break a;
            }
          }
        } catch (A) {
          var q = {
            error: A
          };
        } finally {
          try {
            n && !n.done && (h = u.return) && h.call(u);
          } finally {
            if (q) throw q.error;
          }
        }

        c.view.setUint32(d, r, !0);
        return WASI_ESUCCESS;
      }),
      fd_readdir: wrap(function (a, e, b, d, f) {
        a = k(a, WASI_RIGHT_FD_READDIR);
        c.refreshMemory();
        var h = g.readdirSync(a.path, {
          withFileTypes: !0
        }),
            m = e;

        for (d = Number(d); d < h.length; d += 1) {
          var r = h[d],
              n = isomorphicBuffer.byteLength(r.name);
          if (e - m > b) break;
          c.view.setBigUint64(e, BigIntPolyfill(d + 1), !0);
          e += 8;
          if (e - m > b) break;
          var l = g.statSync(q.resolve(a.path, r.name));
          c.view.setBigUint64(e, BigIntPolyfill(l.ino), !0);
          e += 8;
          if (e - m > b) break;
          c.view.setUint32(e, n, !0);
          e += 4;
          if (e - m > b) break;

          switch (!0) {
            case l.isBlockDevice():
              l = WASI_FILETYPE_BLOCK_DEVICE;
              break;

            case l.isCharacterDevice():
              l = WASI_FILETYPE_CHARACTER_DEVICE;
              break;

            case l.isDirectory():
              l = WASI_FILETYPE_DIRECTORY;
              break;

            case l.isFIFO():
              l = WASI_FILETYPE_SOCKET_STREAM;
              break;

            case l.isFile():
              l = WASI_FILETYPE_REGULAR_FILE;
              break;

            case l.isSocket():
              l = WASI_FILETYPE_SOCKET_STREAM;
              break;

            case l.isSymbolicLink():
              l = WASI_FILETYPE_SYMBOLIC_LINK;
              break;

            default:
              l = WASI_FILETYPE_UNKNOWN;
          }

          c.view.setUint8(e, l);
          e += 1;
          e += 3;
          if (e + n >= m + b) break;
          isomorphicBuffer.from(c.memory.buffer).write(r.name, e);
          e += n;
        }

        c.view.setUint32(f, Math.min(e - m, b), !0);
        return WASI_ESUCCESS;
      }),
      fd_renumber: wrap(function (a, e) {
        k(a, BigIntPolyfill(0));
        k(e, BigIntPolyfill(0));
        g.closeSync(c.FD_MAP.get(a).real);
        c.FD_MAP.set(a, c.FD_MAP.get(e));
        c.FD_MAP.delete(e);
        return WASI_ESUCCESS;
      }),
      fd_seek: wrap(function (a, e, b, d) {
        a = k(a, WASI_RIGHT_FD_SEEK);
        c.refreshMemory();

        switch (b) {
          case WASI_WHENCE_CUR:
            a.offset = (a.offset ? a.offset : BigIntPolyfill(0)) + BigIntPolyfill(e);
            break;

          case WASI_WHENCE_END:
            b = g.fstatSync(a.real).size;
            a.offset = BigIntPolyfill(b) + BigIntPolyfill(e);
            break;

          case WASI_WHENCE_SET:
            a.offset = BigIntPolyfill(e);
        }

        c.view.setBigUint64(d, a.offset, !0);
        return WASI_ESUCCESS;
      }),
      fd_tell: wrap(function (a, e) {
        a = k(a, WASI_RIGHT_FD_TELL);
        c.refreshMemory();
        a.offset || (a.offset = BigIntPolyfill(0));
        c.view.setBigUint64(e, a.offset, !0);
        return WASI_ESUCCESS;
      }),
      fd_sync: wrap(function (a) {
        a = k(a, WASI_RIGHT_FD_SYNC);
        g.fsyncSync(a.real);
        return WASI_ESUCCESS;
      }),
      path_create_directory: wrap(function (a, e, b) {
        a = k(a, WASI_RIGHT_PATH_CREATE_DIRECTORY);
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        e = isomorphicBuffer.from(c.memory.buffer, e, b).toString();
        g.mkdirSync(q.resolve(a.path, e));
        return WASI_ESUCCESS;
      }),
      path_filestat_get: wrap(function (a, e, b, d, f) {
        a = k(a, WASI_RIGHT_PATH_FILESTAT_GET);
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        b = isomorphicBuffer.from(c.memory.buffer, b, d).toString();
        b = g.statSync(q.resolve(a.path, b));
        c.view.setBigUint64(f, BigIntPolyfill(b.dev), !0);
        f += 8;
        c.view.setBigUint64(f, BigIntPolyfill(b.ino), !0);
        f += 8;
        c.view.setUint8(f, translateFileAttributes(c, void 0, b).filetype);
        f += 8;
        c.view.setBigUint64(f, BigIntPolyfill(b.nlink), !0);
        f += 8;
        c.view.setBigUint64(f, BigIntPolyfill(b.size), !0);
        f += 8;
        c.view.setBigUint64(f, msToNs(b.atimeMs), !0);
        f += 8;
        c.view.setBigUint64(f, msToNs(b.mtimeMs), !0);
        c.view.setBigUint64(f + 8, msToNs(b.ctimeMs), !0);
        return WASI_ESUCCESS;
      }),
      path_filestat_set_times: wrap(function (a, b, d, f, m, p, x) {
        a = k(a, WASI_RIGHT_PATH_FILESTAT_SET_TIMES);
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        var e = g.fstatSync(a.real);
        b = e.atime;
        e = e.mtime;
        var h = nsToMs(E(WASI_CLOCK_REALTIME)),
            l = WASI_FILESTAT_SET_ATIM | WASI_FILESTAT_SET_ATIM_NOW;
        if ((x & l) === l) return WASI_EINVAL;
        l = WASI_FILESTAT_SET_MTIM | WASI_FILESTAT_SET_MTIM_NOW;
        if ((x & l) === l) return WASI_EINVAL;
        (x & WASI_FILESTAT_SET_ATIM) === WASI_FILESTAT_SET_ATIM ? b = nsToMs(m) : (x & WASI_FILESTAT_SET_ATIM_NOW) === WASI_FILESTAT_SET_ATIM_NOW && (b = h);
        (x & WASI_FILESTAT_SET_MTIM) === WASI_FILESTAT_SET_MTIM ? e = nsToMs(p) : (x & WASI_FILESTAT_SET_MTIM_NOW) === WASI_FILESTAT_SET_MTIM_NOW && (e = h);
        d = isomorphicBuffer.from(c.memory.buffer, d, f).toString();
        g.utimesSync(q.resolve(a.path, d), new Date(b), new Date(e));
        return WASI_ESUCCESS;
      }),
      path_link: wrap(function (a, b, d, f, m, p, x) {
        a = k(a, WASI_RIGHT_PATH_LINK_SOURCE);
        m = k(m, WASI_RIGHT_PATH_LINK_TARGET);
        if (!a.path || !m.path) return WASI_EINVAL;
        c.refreshMemory();
        d = isomorphicBuffer.from(c.memory.buffer, d, f).toString();
        p = isomorphicBuffer.from(c.memory.buffer, p, x).toString();
        g.linkSync(q.resolve(a.path, d), q.resolve(m.path, p));
        return WASI_ESUCCESS;
      }),
      path_open: wrap(function (a, b, d, f, m, p, x, u, n) {
        b = k(a, WASI_RIGHT_PATH_OPEN);
        p = BigIntPolyfill(p);
        x = BigIntPolyfill(x);
        a = (p & (WASI_RIGHT_FD_READ | WASI_RIGHT_FD_READDIR)) !== BigIntPolyfill(0);
        var e = (p & (WASI_RIGHT_FD_DATASYNC | WASI_RIGHT_FD_WRITE | WASI_RIGHT_FD_ALLOCATE | WASI_RIGHT_FD_FILESTAT_SET_SIZE)) !== BigIntPolyfill(0);
        if (e && a) var h = g.constants.O_RDWR;else a ? h = g.constants.O_RDONLY : e && (h = g.constants.O_WRONLY);
        a = p | WASI_RIGHT_PATH_OPEN;
        p |= x;
        0 !== (m & WASI_O_CREAT) && (h |= g.constants.O_CREAT, a |= WASI_RIGHT_PATH_CREATE_FILE);
        0 !== (m & WASI_O_DIRECTORY) && (h |= g.constants.O_DIRECTORY);
        0 !== (m & WASI_O_EXCL) && (h |= g.constants.O_EXCL);
        0 !== (m & WASI_O_TRUNC) && (h |= g.constants.O_TRUNC, a |= WASI_RIGHT_PATH_FILESTAT_SET_SIZE);
        0 !== (u & WASI_FDFLAG_APPEND) && (h |= g.constants.O_APPEND);
        0 !== (u & WASI_FDFLAG_DSYNC) && (h = g.constants.O_DSYNC ? h | g.constants.O_DSYNC : h | g.constants.O_SYNC, p |= WASI_RIGHT_FD_DATASYNC);
        0 !== (u & WASI_FDFLAG_NONBLOCK) && (h |= g.constants.O_NONBLOCK);
        0 !== (u & WASI_FDFLAG_RSYNC) && (h = g.constants.O_RSYNC ? h | g.constants.O_RSYNC : h | g.constants.O_SYNC, p |= WASI_RIGHT_FD_SYNC);
        0 !== (u & WASI_FDFLAG_SYNC) && (h |= g.constants.O_SYNC, p |= WASI_RIGHT_FD_SYNC);
        e && 0 === (h & (g.constants.O_APPEND | g.constants.O_TRUNC)) && (p |= WASI_RIGHT_FD_SEEK);
        c.refreshMemory();
        d = isomorphicBuffer.from(c.memory.buffer, d, f).toString();
        d = q.resolve(b.path, d);
        if (q.relative(b.path, d).startsWith("..")) return WASI_ENOTCAPABLE;

        try {
          var r = g.realpathSync(d);
          if (q.relative(b.path, r).startsWith("..")) return WASI_ENOTCAPABLE;
        } catch (A) {
          if ("ENOENT" === A.code) r = d;else throw A;
        }

        try {
          var t = g.statSync(r).isDirectory();
        } catch (A) {}

        h = !e && t ? g.openSync(r, g.constants.O_RDONLY) : g.openSync(r, h);
        t = __spread(c.FD_MAP.keys()).reverse()[0] + 1;
        c.FD_MAP.set(t, {
          real: h,
          filetype: void 0,
          rights: {
            base: a,
            inheriting: p
          },
          path: r
        });
        stat(c, t);
        c.view.setUint32(n, t, !0);
        return WASI_ESUCCESS;
      }),
      path_readlink: wrap(function (a, b, d, f, m, p) {
        a = k(a, WASI_RIGHT_PATH_READLINK);
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        b = isomorphicBuffer.from(c.memory.buffer, b, d).toString();
        b = q.resolve(a.path, b);
        b = g.readlinkSync(b);
        f = isomorphicBuffer.from(c.memory.buffer).write(b, f, m);
        c.view.setUint32(p, f, !0);
        return WASI_ESUCCESS;
      }),
      path_remove_directory: wrap(function (a, b, d) {
        a = k(a, WASI_RIGHT_PATH_REMOVE_DIRECTORY);
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        b = isomorphicBuffer.from(c.memory.buffer, b, d).toString();
        g.rmdirSync(q.resolve(a.path, b));
        return WASI_ESUCCESS;
      }),
      path_rename: wrap(function (a, b, d, f, m, p) {
        a = k(a, WASI_RIGHT_PATH_RENAME_SOURCE);
        f = k(f, WASI_RIGHT_PATH_RENAME_TARGET);
        if (!a.path || !f.path) return WASI_EINVAL;
        c.refreshMemory();
        b = isomorphicBuffer.from(c.memory.buffer, b, d).toString();
        m = isomorphicBuffer.from(c.memory.buffer, m, p).toString();
        g.renameSync(q.resolve(a.path, b), q.resolve(f.path, m));
        return WASI_ESUCCESS;
      }),
      path_symlink: wrap(function (a, b, d, f, m) {
        d = k(d, WASI_RIGHT_PATH_SYMLINK);
        if (!d.path) return WASI_EINVAL;
        c.refreshMemory();
        a = isomorphicBuffer.from(c.memory.buffer, a, b).toString();
        f = isomorphicBuffer.from(c.memory.buffer, f, m).toString();
        g.symlinkSync(a, q.resolve(d.path, f));
        return WASI_ESUCCESS;
      }),
      path_unlink_file: wrap(function (a, b, d) {
        a = k(a, WASI_RIGHT_PATH_UNLINK_FILE);
        if (!a.path) return WASI_EINVAL;
        c.refreshMemory();
        b = isomorphicBuffer.from(c.memory.buffer, b, d).toString();
        g.unlinkSync(q.resolve(a.path, b));
        return WASI_ESUCCESS;
      }),
      poll_oneoff: function (a, b, d, f) {
        var e = 0,
            g = 0;
        c.refreshMemory();

        for (var h = 0; h < d; h += 1) {
          var k = c.view.getBigUint64(a, !0);
          a += 8;
          var n = c.view.getUint8(a);
          a += 1;

          switch (n) {
            case WASI_EVENTTYPE_CLOCK:
              a += 7;
              c.view.getBigUint64(a, !0);
              a += 8;
              var l = c.view.getUint32(a, !0);
              a += 4;
              a += 4;
              n = c.view.getBigUint64(a, !0);
              a += 8;
              c.view.getBigUint64(a, !0);
              a += 8;
              var r = c.view.getUint16(a, !0);
              a += 2;
              a += 6;
              var q = 1 === r;
              r = WASI_ESUCCESS;
              l = BigIntPolyfill(E(l));
              null === l ? r = WASI_EINVAL : (n = q ? n : l + n, g = n > g ? n : g);
              c.view.setBigUint64(b, k, !0);
              b += 8;
              c.view.setUint16(b, r, !0);
              b += 2;
              c.view.setUint8(b, WASI_EVENTTYPE_CLOCK);
              b += 1;
              b += 5;
              e += 1;
              break;

            case WASI_EVENTTYPE_FD_READ:
            case WASI_EVENTTYPE_FD_WRITE:
              a += 3;
              c.view.getUint32(a, !0);
              a += 4;
              c.view.setBigUint64(b, k, !0);
              b += 8;
              c.view.setUint16(b, WASI_ENOSYS, !0);
              b += 2;
              c.view.setUint8(b, n);
              b += 1;
              b += 5;
              e += 1;
              break;

            default:
              return WASI_EINVAL;
          }
        }

        for (c.view.setUint32(f, e, !0); w.hrtime() < g;);

        return WASI_ESUCCESS;
      },
      proc_exit: function (a) {
        w.exit(a);
        return WASI_ESUCCESS;
      },
      proc_raise: function (a) {
        if (!(a in SIGNAL_MAP)) return WASI_EINVAL;
        w.kill(SIGNAL_MAP[a]);
        return WASI_ESUCCESS;
      },
      random_get: function (a, b) {
        c.refreshMemory();
        w.randomFillSync(new Uint8Array(c.memory.buffer), a, b);
        return WASI_ESUCCESS;
      },
      sched_yield: function () {
        return WASI_ESUCCESS;
      },
      sock_recv: function () {
        return WASI_ENOSYS;
      },
      sock_send: function () {
        return WASI_ENOSYS;
      },
      sock_shutdown: function () {
        return WASI_ENOSYS;
      }
    };
    b.traceSyscalls && Object.keys(this.wasiImport).forEach(function (a) {
      var b = c.wasiImport[a];

      c.wasiImport[a] = function () {
        for (var c = [], d = 0; d < arguments.length; d++) c[d] = arguments[d];

        console.log("WASI: wasiImport called: " + a + " (" + c + ")");

        try {
          var e = b.apply(void 0, __spread(c));
          console.log("WASI:  => " + e);
          return e;
        } catch (p) {
          throw console.log("Catched error: " + p), p;
        }
      };
    });
  }

  d.prototype.refreshMemory = function () {
    this.view && 0 !== this.view.buffer.byteLength || (this.view = new DataViewPolyfill(this.memory.buffer));
  };

  d.prototype.setMemory = function (b) {
    this.memory = b;
  };

  d.prototype.start = function (b) {
    b = b.exports;
    if (null === b || "object" !== typeof b) throw Error("instance.exports must be an Object. Received " + b + ".");
    var d = b.memory;
    if (!(d instanceof WebAssembly.Memory)) throw Error("instance.exports.memory must be a WebAssembly.Memory. Recceived " + d + ".");
    this.setMemory(d);
    b._start && b._start();
  };

  d.prototype.getImportNamespace = function (b) {
    var d,
        c = null;

    try {
      for (var t = __values(WebAssembly.Module.imports(b)), v = t.next(); !v.done; v = t.next()) {
        var y = v.value;
        if ("function" === y.kind && y.module.startsWith("wasi_")) if (!c) c = y.module;else if (c !== y.module) throw Error("Multiple namespaces detected.");
      }
    } catch (g) {
      var w = {
        error: g
      };
    } finally {
      try {
        v && !v.done && (d = t.return) && d.call(t);
      } finally {
        if (w) throw w.error;
      }
    }

    return c;
  };

  d.prototype.getImports = function (b) {
    switch (this.getImportNamespace(b)) {
      case "wasi_unstable":
        return {
          wasi_unstable: this.wasiImport
        };

      case "wasi_snapshot_preview1":
        return {
          wasi_snapshot_preview1: this.wasiImport
        };

      default:
        throw Error("Can't detect a WASI namespace for the WebAssembly Module");
    }
  };

  d.defaultBindings = defaultBindings;
  return d;
}(),
    WASI = WASIDefault;

exports.WASI = WASI;
exports.WASIError = WASIError;
exports.WASIExitError = WASIExitError;
exports.WASIKillError = WASIKillError;
exports.default = WASIDefault;
export default exports;
export const __esModule = exports.__esModule;
const _WASI = exports.WASI,
      _WASIError = exports.WASIError,
      _WASIExitError = exports.WASIExitError,
      _WASIKillError = exports.WASIKillError;
export { _WASI as WASI, _WASIError as WASIError, _WASIExitError as WASIExitError, _WASIKillError as WASIKillError };